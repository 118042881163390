<template>
  <DetailEventForm ref="form" />
</template>

<script>
import DetailEventForm from '../../Form/Detail';

export default {
  name: 'SettingEventRegisterChildDetail',
  components: {
    DetailEventForm
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
  }
};
</script>
